import {defineStore} from 'pinia'
import {computed, ref, watch} from 'vue'
import {useHead} from '#imports'
import type {ModalIds} from "~/types/Modals";

interface ModalCallbacks {
    onConfirm?: (data?: any) => void
    onCancel?: (data?: any) => void
    onClose?: (data?: any) => void
}

interface ModalData {
    data?: any
    callbacks?: ModalCallbacks
    zIndex?: number
}

export const useModalStore = defineStore('modal', () => {
    const activeModals = ref<Set<ModalIds>>(new Set())
    const modalData = ref<Record<ModalIds, ModalData>>({} as Record<ModalIds, ModalData>)
    const modalStack = ref<ModalIds[]>([]) // Стек для отслеживания порядка модалок
    const baseZIndex = 1000 // Базовый z-index для первой модалки

    // Следим за активными модалками
    watch(() => activeModals.value.size, (size) => {
        useHead({
            bodyAttrs: {
                style: size > 0 ? 'overflow: hidden' : '',
                'data-modal': size > 0 ? 'true' : 'false',
            },
        })
    })

    // Геттеры как обычные функции
    const isModalOpen = (modalId: ModalIds) => activeModals.value.has(modalId)
    const getModalData = (modalId: ModalIds) => modalData.value[modalId]?.data
    const getModalCallbacks = (modalId: ModalIds) => modalData.value[modalId]?.callbacks
    const getModalZIndex = (modalId: ModalIds) => modalData.value[modalId]?.zIndex ?? 0
    const getOverlayZIndex = (modalId: ModalIds) => (modalData.value[modalId]?.zIndex ?? 0) - 1

    // Получить текущую верхнюю модалку
    const topModalId = computed(() => modalStack.value[modalStack.value.length - 1])

    // Действия
    function openModal(modalId: ModalIds, data: any = null, callbacks?: ModalCallbacks) {
        activeModals.value.add(modalId)

        // Добавляем модалку в стек и назначаем z-index
        if (!modalStack.value.includes(modalId)) {
            modalStack.value.push(modalId)
        }

        const zIndex = baseZIndex + (modalStack.value.length - 1) * 2 // Умножаем на 2, чтобы оставить место для оверлея

        modalData.value[modalId] = {
            data,
            callbacks,
            zIndex
        }
    }

    function closeModal(modalId: ModalIds, triggerCallback: keyof ModalCallbacks = 'onClose', data?: any) {
        const callbacks = modalData.value[modalId]?.callbacks
        if (callbacks && callbacks[triggerCallback]) {
            callbacks[triggerCallback]?.(data)
        }

        activeModals.value.delete(modalId)
        // Удаляем модалку из стека
        modalStack.value = modalStack.value.filter(id => id !== modalId)
        delete modalData.value[modalId]

        // Обновляем z-index для оставшихся модалок
        modalStack.value.forEach((id, index) => {
            if (modalData.value[id]) {
                modalData.value[id].zIndex = baseZIndex + index * 2
            }
        })
    }

    // function confirmModal(modalId: ModalIds) {
    //     closeModal(modalId, 'onConfirm')
    // }
    //
    // function cancelModal(modalId: ModalIds) {
    //     closeModal(modalId, 'onCancel')
    // }
    //
    // function closeAllModals() {
    //     [...modalStack.value].reverse().forEach(modalId => {
    //         const callbacks = modalData.value[modalId]?.callbacks
    //         callbacks?.onClose?.()
    //     })
    //
    //     activeModals.value.clear()
    //     modalData.value = {} as Record<ModalIds, ModalData>
    //     modalStack.value = []
    // }

    return {
        // Состояние
        activeModals,
        modalData,
        modalStack,
        topModalId,

        // Геттеры
        isModalOpen,
        getModalData,
        getModalCallbacks,
        getModalZIndex,
        getOverlayZIndex,

        // Действия
        openModal,
        closeModal,
    }
}) 
